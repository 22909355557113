import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  messageLoginNaoAutorizado:boolean = false;
  messageFalhaLogin:boolean = false;
  email;
  password;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  ngOnInit() {
  }

  onSubmit(formData) {
    if (formData.valid) {
      this.authService.login(
        formData.value.email,
        formData.value.password
      )
      .then((res: any) => {
        console.log(res);
        if(res != undefined){
          this.router.navigateByUrl('/addEstablishment');
        }else{
          this.messageLoginNaoAutorizado = true;
          setTimeout(() => this.messageLoginNaoAutorizado = false, 5000);
        }
          
      })
      .catch((error: any) => {  
        this.messageFalhaLogin = true;
        setTimeout(() => this.messageFalhaLogin = false, 5000);
      });
    }
  }

}
