import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MaterialModule } from './material.module';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';

import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import 'firebase/storage';

import { AppRoutingModule } from './app-routing.module';


import { AppComponent } from './app.component';
import { AddEstablishmentComponent, DialogDelete, DialogEdit } from './add-establishment/add-establishment.component';
import { AuthService } from './auth.service';

import { NgxGeoautocompleteModule } from 'ngx-geoautocomplete';
import { LoginComponent } from './login/login.component';

export const firebaseConfig = {
  apiKey: "AIzaSyDr92X2YeYYt2Gk3b8PZR_HGnxxqqt2kDw",
  authDomain: "fibud-23fbb.firebaseapp.com",
  databaseURL: "https://fibud-23fbb.firebaseio.com",
  projectId: "fibud-23fbb",
  storageBucket: "fibud-23fbb.appspot.com",
  messagingSenderId: "601768757165"
};

@NgModule({
  declarations: [
    AppComponent,
    AddEstablishmentComponent,
    DialogEdit,
    DialogDelete,
    LoginComponent
  ],
  entryComponents:[
    DialogEdit,
    DialogDelete
  ],
  imports: [
    BrowserModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    AppRoutingModule,
    FormsModule,
    NgbModule.forRoot(),
    NgxGeoautocompleteModule.forRoot(),
    MaterialModule,
    NgMatSearchBarModule
  ],
  providers: [
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
