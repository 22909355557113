import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import * as firebase from 'firebase/app';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-establishment',
  templateUrl: './add-establishment.component.html',
  styleUrls: ['./add-establishment.component.css']
})
export class AddEstablishmentComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('form2') form2: NgForm;
  @ViewChild('form3') form3: NgForm;
  @ViewChild('form4') form4: NgForm;
  /* pegar os dados gerais do novo usuário */
  /* variáveis para pegar localização, latitude e longitude */
  userSettings = {}
  locationSettings = {}
  localizacaoCompleta = {
    latitude: '',
    longitude: '',
    address: ''
  };

  newuser = {
    name: '',
    description: '',
    phone: '',
    location: 
    [
    'São Paulo, SP',
    'Rio de Janeiro, RJ'
    ],
    pacote: 
    [
    'Free',
    'Basic',
    'Intermed',
    'Premium'
    ],
    web: '',
    codPesquisa: ''
  }
  categoryListCadastro = [];
  subcategoryListCadastro = [];

  establishmentPhoto;
  uploadArquivo: String;
  progressBarValue;
  urlPhoto;
  referenciaStorage;

  /* pegar os id's categoria e subcategoria e location(CIDADE/ESTADO) */
  category = 0;
  subcategory = 0;
  location = 'São Paulo, SP';
  pacote = '';
  plano = '';

  botaoCadastroEstablishment: boolean = true;
  messageCadastrando: boolean = false;
  messageCadastroSucess:boolean = false;
  messageAddPhoto:boolean = false;
  messageAddPacote:boolean = false;
  messageAddCategory: boolean = false;

  /* Fim de pegar os dados usuário 
  *********************************/

  /* Inicio Adicionar Promoção/Bônus
  */
  categoryIdAddPromo;
  subcategoryIdAddPromo;
  establishmentAddPromo;
  establishmentIdAddPromo;

  categoryListDiscout = [];
  subcategoryListAddPromo = [];

  addPromo:boolean = false;
  listPromo:boolean = false;
  categoryNameDiscount: String;

  newpromo = {
    tituloPromo: '',
    descriptionPromo: '',
    validadeInicioPromo: '',
    validadeFimPromo: ''
  }

  today = new Date().toJSON().split('T')[0];
  porcentagem;
  valorProduto;
  valorPromocao;
  porcentagemValor;
  brinde;
  typePromo = 1;
  hotList: boolean;
  descontoPhoto;

  promoValor: boolean = true;
  promoPorcentagem: boolean = false;
  promoBrinde: boolean = false;

  listaPromotions = [];
  nomeSelecionado: any;

  botaoCadastroPromotion: boolean = true;
  messageImgDesconto:boolean = false;
  messageCadastroPromoSucess:boolean = false;
  messageValorIncorreto:boolean = false;
  messageSemValores:boolean = false;
  messagePorcentagem:boolean = false;
  messageBrinde:boolean = false;
  messageDeletePromoSucess:boolean = false;
  
  /* fim
  **********************************/

  /* Inicio Listar/Editar/Excluir 
  */

  /*Lista de estabeleciomentos filtrado por categoria e subcategoria */
  categoryList = [];
  subcategoryListEdit = [];

  categoryIdList;
  subcategoryIdList;
  establishmentList;
  establishmentId;

  /* Categoria e subcategoria edição */
  subcategoryEdit = [];

  categoryIdEdit;
  subcategoryIdEdit;
  locationEdit = 'São Paulo, SP';

  edituser = {
    name: '',
    description: '',
    phone: '',
    location: '',
    web: '',
    address: '',
    latitude: '',
    longitude: '',
    categoryId: '',
    subcategoryId: '',
    subcategoryName: ''
  }
  editAddress = false;
  editCity = false;
  editCategory = false;

  localizacaoCompletaEdit = {
    latitude: '',
    longitude: '',
    address: ''
  };

  nameEstablishment: any;
  planoEdit = '';
  pacoteEdit = '';

  establishmentLogo;

  divEditPacoteEstablishment: boolean = false;
  botaoEditPacote: boolean = false;
  messageEditPacote: boolean = false;
  messageEditPacoteSucess: boolean = false;

  divEditEstablishment: boolean = false;
  divAddLogoEstablishment: boolean = false;
  botaoAddLogo: boolean = false;
  messageAdicionandoLogo: boolean = false;
  messageAddLogoSucess: boolean = false;

  /* Fim Listar/Editar/Excluir 
  *****************************/

  /*************************
   * Inicio UTILITY
   */
  newUtility = {
    name: '',
    phone: '',
  }

  utilitySettings = {}
  localizacaoUtilityCompleta = {
    latitude: '',
    longitude: '',
    address: ''
  };

  messageUtilityCadastroSucess: boolean = false;

  /* MODAL'S */
  successMessageDelete: boolean = false;
  successMessageEdit:boolean = false;
  deleteMessage: boolean = false;
  failMessage: boolean = false;

  constructor(
    private router: Router, 
    public zone: NgZone,
    public authService: AuthService,
    public dialog: MatDialog
  ) {
    this.referenciaStorage = firebase.storage().ref();
  }

  ngOnInit() {
    this.authService.consultarCategoria().then((res: any) => {
      console.log('Consulta Category');
      this.categoryListCadastro = res;
      this.categoryList = res;
      this.categoryListDiscout = res;
    })
  }
/* Começo - adicionar Estabelecimento */  
  onChangeCategory(idCategory) {
    this.category = idCategory;
    this.authService.consultarSubcategoria(idCategory).then((res: any) => {
      console.log('Consulta SubCategory');
      this.subcategoryListCadastro = res;
    })
  } 

  onChangeSubCategory(event: any) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    //pegar ID e Nome da subcategoria selecionada
    this.subcategory = event.target.value;
    this.newuser.codPesquisa = selectElementText;
  }

  onChangeLocation(locationChanged){
    this.location = locationChanged;
  }

  onChangePacote(pacoteChanged){
    this.pacote = pacoteChanged;
  }

  onChangePlanoPacote(planoPacoteChanged){
    this.plano = planoPacoteChanged;
  }

  autoCompleteCallback1(selectedData:any) {
    this.localizacaoCompleta = {
      address: selectedData.data.description,
      latitude: selectedData.data.geometry.location.lat,
      longitude: selectedData.data.geometry.location.lng
    }
    console.log(this.localizacaoCompleta);
  }

  atualizaArquivo(event) {
    this.establishmentPhoto = event.target.files[0]; 
    this.uploadArquivo = "Foto selecionada com sucesso!";
    this.progressBarValue = 0;
  }
  
  addEstablishment() {
    if(this.establishmentPhoto == null){
      this.messageAddPhoto = true;
      setTimeout(() => this.messageAddPhoto = false, 5000);
    }else if(this.pacote == '' || this.plano == ''){
      this.messageAddPacote = true;
      setTimeout(() => this.messageAddPacote = false, 5000);
    }else if(this.category == 0 || this.subcategory == 0){
      this.messageAddCategory = true;
      setTimeout(() => this.messageAddCategory = false, 5000);
    }else {
      this.botaoCadastroEstablishment = false;
      this.messageCadastrando = true;
      this.authService.cadastrarEstablishment(this.newuser, this.localizacaoCompleta, this.location, this.category, this.subcategory, this.establishmentPhoto, this.pacote, this.plano).then((res: any) => {
        console.log('Estabelecimento cadastrado com sucesso');
        this.form.reset();
        this.botaoCadastroEstablishment = true;
        this.messageCadastrando = false;
        this.messageCadastroSucess = true;
        setTimeout(() => this.messageCadastroSucess = false, 4000);
      })
    } 
  }
  
  /* Fim - adicionar Estabelecimento 
  ***********************************/  

  /* Inicio - Adicionar Promoção/Bônus */

  onChangeCategoryAddPromo(idCategory) {
    this.categoryIdAddPromo = idCategory;
    this.addPromo = false;
    this.listPromo = false;
    this.listaPromotions = [];
    this.authService.consultarSubcategoria(idCategory).then((res: any) => {
      this.subcategoryListAddPromo = res;
    })
  } 

  onChangeSubCategoryAddPromo(idSubcategoryAddPromo) {
    this.subcategoryIdAddPromo = idSubcategoryAddPromo;
    this.addPromo = false;
    this.listPromo = false;
    this.listaPromotions = [];
    this.authService.consultarEstablishments(this.categoryIdAddPromo, idSubcategoryAddPromo)
    .then((res: any) => {
      this.establishmentAddPromo = res;
    })
    .catch((error: any) => {
      console.log(error);
    });
  }

  atualizaDesconto(event) {
    this.descontoPhoto = event.target.files[0]; 
  }

  promoType(promoValue){
    this.typePromo = promoValue;
    if(promoValue == 1){
      this.promoValor = true;
      this.promoPorcentagem = false;
      this.promoBrinde = false;
      this.porcentagem = null;
      this.brinde = null;
    }else if(promoValue == 2){
      this.promoValor = false;
      this.promoPorcentagem = true;
      this.promoBrinde = false;
      this.valorProduto = null;
      this.valorPromocao = null;
      this.brinde = null;
    }else if(promoValue == 3){
      this.promoValor = false;
      this.promoPorcentagem = false;
      this.promoBrinde = true;
      this.valorProduto = null;
      this.valorPromocao = null;
      this.porcentagem = null;
    }
  }

  openAddDesconto(idEstablishment, name){
    if(this.establishmentId != idEstablishment){
      this.listPromo = false;
    }
    if(this.nomeSelecionado != name){
      this.nomeSelecionado = name;
    }
    this.establishmentId = idEstablishment;
    console.log(idEstablishment);
    this.addPromo = true;
  }

  listEditPromo(idEstablishment, name){
    if(this.establishmentId != idEstablishment){
      this.addPromo = false;
    }
    if(this.nomeSelecionado != name){
      this.nomeSelecionado = name;
    }
    this.establishmentId = idEstablishment;
    this.listPromo = true;
    this.authService.consultarPromotions(idEstablishment)
      .then((res: any) => {
        this.listaPromotions = res;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  addPromotion(){
    if(this.descontoPhoto == null){
      this.messageImgDesconto = true;
      setTimeout(() => this.messageImgDesconto = false, 5000);
    }else{
    this.authService.consultarCategoriaSelecionada(this.categoryIdAddPromo)
      .then((res: any) => {
        this.categoryNameDiscount = res.name;
        if(this.promoValor == true) {
          if(this.valorProduto <= this.valorPromocao){
            this.messageValorIncorreto = true;
            setTimeout(() => this.messageValorIncorreto = false, 5000);
          }else if(this.valorProduto == null || this.valorPromocao == null){
            this.messageSemValores = true;
            setTimeout(() => this.messageSemValores = false, 5000);
          }
          else{
            this.botaoCadastroPromotion = false;
            this.messageCadastrando = true;
            this.porcentagemValor = ((this.valorPromocao * 100) / this.valorProduto);
            this.porcentagemValor = 100 - this.porcentagemValor;
            this.porcentagemValor = Math.round(this.porcentagemValor);
            this.hotList = true;
            this.authService.cadastrarPromotion(this.newpromo, this.establishmentId, this.porcentagemValor, this.valorProduto, this.valorPromocao, this.porcentagem, this.brinde, this.typePromo, this.hotList, this.categoryNameDiscount, this.descontoPhoto).then((res: any) => {
              this.form2.reset();
              this.zone.run(() => {
                this.authService.consultarPromotions(this.establishmentId)
                .then((res: any) => {
                  this.listaPromotions = res;
                  this.botaoCadastroPromotion = true;
                  this.messageCadastrando = false;
                  this.listPromo = true;
                  this.porcentagemValor =  null;
                  this.messageCadastroPromoSucess = true;
                  setTimeout(() => this.messageCadastroPromoSucess = false, 5000);
                })
              })
            })
          }
        }
    
        else if(this.promoPorcentagem == true){
          if(this.porcentagem > 100 || this.porcentagem < 1){
            this.messagePorcentagem = true;
            setTimeout(() => this.messagePorcentagem = false, 5000);
          }else if(this.porcentagem == null) {
            this.messagePorcentagem = true;
            setTimeout(() => this.messagePorcentagem = false, 5000);
          }else{
            this.botaoCadastroPromotion = false;
            this.messageCadastrando = true;
            this.hotList = true;
            this.authService.cadastrarPromotion(this.newpromo, this.establishmentId, this.porcentagemValor, this.valorProduto, this.valorPromocao, this.porcentagem, this.brinde, this.typePromo, this.hotList, this.categoryNameDiscount, this.descontoPhoto).then((res: any) => {
              this.form2.reset();
              this.zone.run(() => {
                this.authService.consultarPromotions(this.establishmentId)
                .then((res: any) => {
                  this.listaPromotions = res;
                  this.listPromo = true;
                  this.botaoCadastroPromotion = true;
                  this.messageCadastrando = false;
                  this.porcentagemValor =  null;
                  this.messageCadastroPromoSucess = true;
                  setTimeout(() => this.messageCadastroPromoSucess = false, 5000);
                })
              })
            })
          }
        }
        
        else if(this.promoBrinde == true){
          if(this.brinde == null){
            this.messageBrinde = true;
            setTimeout(() => this.messageBrinde = false, 5000);
          }else{
            this.botaoCadastroPromotion = false;
            this.messageCadastrando = true;
            this.hotList = false;
            this.authService.cadastrarPromotion(this.newpromo, this.establishmentId, this.porcentagemValor, this.valorProduto, this.valorPromocao, this.porcentagem, this.brinde, this.typePromo, this.hotList, this.categoryNameDiscount, this.descontoPhoto).then((res: any) => {
              this.form2.reset();
              this.zone.run(() => {
                this.authService.consultarPromotions(this.establishmentId)
                .then((res: any) => {
                  this.listaPromotions = res;
                  this.listPromo = true;
                  this.botaoCadastroPromotion = true;
                  this.messageCadastrando = false;
                  this.porcentagemValor =  null;
                  this.messageCadastroPromoSucess = true;
                  setTimeout(() => this.messageCadastroPromoSucess = false, 5000);
                })
              })
            })
          }
        }
      })
    }
  }

  /* 
  ***********************************/
  
  /* Começo - Listar/Editar/Excluir */  
  onChangeCategoryList(idCategoryList) {
    this.categoryIdList = idCategoryList;
    this.divEditEstablishment = false;
    this.divAddLogoEstablishment = false;
    this.authService.consultarSubcategoria(idCategoryList).then((res: any) => {
      this.subcategoryListEdit = res;
    })
  }
  
  onChangeSubCategoryList(idSubcategoryList) {
    this.subcategoryIdList = idSubcategoryList;
    this.divEditEstablishment = false;
    this.divAddLogoEstablishment = false;
    this.authService.consultarEstablishments(this.categoryIdList, idSubcategoryList)
    .then((res: any) => {
      this.establishmentList = res;
    })
    .catch((error: any) => {
      console.log(error);
    });
  }

  establishmentEditPacote(idEstablishment, nameEstablishment){
    this.establishmentId = idEstablishment;
    this.nameEstablishment = nameEstablishment;
    this.divEditPacoteEstablishment = true;
  }

  onChangePacoteEdit(pacoteChanged){
    this.pacoteEdit = pacoteChanged;
    if(this.pacoteEdit != '' && this.planoEdit != ''){
      this.botaoEditPacote = true;
    }
  }

  onChangePlanoPacoteEdit(planoPacoteChanged){
    this.planoEdit = planoPacoteChanged;
    if(this.pacoteEdit != '' && this.planoEdit != ''){
      this.botaoEditPacote = true;
    }
  }

  editPacote(){
    if(this.pacoteEdit != '' && this.planoEdit != ''){
      this.botaoEditPacote = false;
      this.messageEditPacote = true;
      this.authService.editPacote(this.pacoteEdit, this.planoEdit, this.establishmentId).then((res: any) => {
        this.zone.run(() => {
          this.authService.consultarEstablishments(this.categoryIdList, this.subcategoryIdList)
            .then((res: any) => {
              this.divEditPacoteEstablishment = false;
              this.establishmentList = res;
              this.pacoteEdit = '';
              this.planoEdit = '';
            })
        })
        this.messageEditPacote = false;
        this.messageEditPacoteSucess = true;
        setTimeout(() => this.messageEditPacoteSucess = false, 4000);
      })
    }
  }

  establishmentAddLogo(idEstablishment){
    this.establishmentId = idEstablishment;
    this.divAddLogoEstablishment = true;
  }

  atualizaLogo(event) {
    this.establishmentLogo = event.target.files[0]; 
    this.botaoAddLogo = true;
  }

  addLogo(){
    if(this.establishmentLogo != null){
      this.botaoAddLogo = false;
      this.messageAdicionandoLogo = true;
      this.authService.adicionarLogo(this.establishmentId, this.establishmentLogo).then((res: any) => {
        console.log('Logo adicionado com sucesso');
        this.messageAdicionandoLogo = false;
        this.messageAddLogoSucess = true;
        setTimeout(() => this.messageAddLogoSucess = false, 4000);
      })
    }
  }

  establishmentSelect(idEstablishment){
    this.establishmentId = idEstablishment;
    this.divEditEstablishment = true;
    this.authService.consultarEstablishment(idEstablishment)
    .then((res: any) => {
      this.edituser = res;
      console.log(this.edituser);
    })
    .catch((error: any) => {
      console.log(error);
    });
  }

  editeAdress(){
    this.editAddress = !this.editAddress;
  }

  editeCity(){
    this.editCity = !this.editCity;
  }

  editeCategory(){
    this.editCategory = !this.editCategory;
  }

  autoCompleteCallback2(selectedData:any) {
    this.localizacaoCompletaEdit = {
      address: selectedData.data.description,
      latitude: selectedData.data.geometry.location.lat,
      longitude: selectedData.data.geometry.location.lng
    }
    
  }

  onChangeCategoryEdit(idCategoryEdit) {
    this.categoryIdEdit = idCategoryEdit;

    this.authService.consultarSubcategoria(idCategoryEdit).then((res: any) => {
      this.subcategoryEdit = res;
    })
  }
  
  onChangeSubCategoryEdit(event: any) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    //pegar ID e Nome da subcategoria selecionada
    this.subcategoryIdEdit = event.target.value;
    this.edituser.subcategoryName = selectElementText;
  }

  onChangeLocationEdit(locationChanged){
    this.locationEdit = locationChanged;
  }

  editEstablishment(){
    const dialogRef = this.dialog.open(DialogEdit, {
      height: '200px'
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.successMessageEdit = true;
        setTimeout(() => this.successMessageEdit = false, 5000);
        if(this.locationEdit != null){
          this.edituser.location = this.locationEdit
        }
        if(this.localizacaoCompletaEdit.address != ""){
          this.authService.editAddress(this.localizacaoCompletaEdit, this.establishmentId).then((res: any) => {
            console.log('Endereço atualizado com sucesso');
          })
        }
        if(this.categoryIdEdit != null){
          this.edituser.categoryId = this.categoryIdEdit;
        }
        if(this.subcategoryIdEdit != null){
          this.edituser.subcategoryId = this.subcategoryIdEdit;
        }
        this.authService.editEstablishment(this.edituser, this.establishmentId).then((res: any) => {
          this.zone.run(() => {
            this.authService.consultarEstablishments(this.categoryIdList, this.subcategoryIdList)
              .then((res: any) => {
                this.editAddress = false;
                this.editCity = false;
                this.editCategory = false;
                this.establishmentList = res;
              })
          })
        })
      }else{
      }
     });
  }
  /* Fim - Listar/Editar/Excluir 
 ***************************** */

   /* INICIO UTILITY 
 ***************************** */

autoCompleteCallbackUtility(selectedData:any) {
  this.localizacaoUtilityCompleta = {
    address: selectedData.data.description,
    latitude: selectedData.data.geometry.location.lat,
    longitude: selectedData.data.geometry.location.lng
  }
  console.log(this.localizacaoCompleta);
}

addUtility() {
  this.authService.cadastrarUtility(this.newUtility, this.localizacaoUtilityCompleta).then((res: any) => {
    console.log('Utilidade cadastrada com sucesso');
    this.form4.reset();
    this.messageUtilityCadastroSucess = true;
    setTimeout(() => this.messageCadastroSucess = false, 5000);
  })
}


   /* FIM UTILITY 
 ***************************** */

  deletarUsuario(idEstablishment){
  const dialogRef = this.dialog.open(DialogDelete, {
    height: '200px'
    
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result == true){
      this.successMessageDelete = true;
      setTimeout(() => this.successMessageDelete = false, 5000);
      this.authService.deleteEstablishment(idEstablishment).then((res: any) => {
        this.zone.run(() => {
          this.authService.consultarEstablishments(this.categoryIdList, this.subcategoryIdList)
            .then((res: any) => {
              this.establishmentList = res;
            })
        })
      })
    }else{
    }
   });
  }

  deletePromotion(idPromotion){
    const dialogRef = this.dialog.open(DialogDelete, {
      height: '200px'
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.messageDeletePromoSucess = true;
        setTimeout(() => this.messageDeletePromoSucess = false, 5000);
        this.authService.deletePromotion(this.establishmentId, idPromotion).then((res: any) => {
          this.zone.run(() => {
            this.authService.consultarPromotions(this.establishmentId)
            .then((res: any) => {
              this.listaPromotions = res;
            })
          })
        })
      }else{
      }
     });
  }

}

 //Dialogs (modal para DELETAR, EDITAR)
 
@Component({
  selector: 'dialog-edit',
  templateUrl: 'dialog-edit.html',
})
export class DialogEdit {
  constructor(
    public dialogRef: MatDialogRef<DialogEdit>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

 @Component({
  selector: 'dialog-delete',
  templateUrl: 'dialog-delete.html',
})
export class DialogDelete {
  constructor(
    public dialogRef: MatDialogRef<DialogDelete>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
