import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { AddEstablishmentComponent } from './add-establishment/add-establishment.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'addEstablishment', component: AddEstablishmentComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  nivel: string;

  constructor(
    afAuth: AngularFireAuth,
    private router: Router
  ) {
    
    this.router.navigateByUrl('/login');
  }
}