import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as geofirex from 'geofirex';
import * as firebaseApp from 'firebase/app';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  geo = geofirex.init(firebaseApp);
  consultaUsers = this.afstore.collection('usersAdmin').ref;
  consultaCategory = this.afstore.collection('category').ref;
  consultaEstablishment = this.afstore.collection('establishment').ref;
  consultaUtility = this.afstore.collection('utility').ref;

  task: AngularFireUploadTask;
  task2: AngularFireUploadTask;
  taskPromo: AngularFireUploadTask;
  ref;
  ref2;
  refPromo;
  
  downloadURL;

  constructor(
    private afAuth: AngularFireAuth,
    public afstore: AngularFirestore,
    public storage: AngularFireStorage,
    private router: Router
  ) { }

  login(email: string, password: string) {
    var promise = new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(value => {
        this.consultaUsers.doc(value.user.uid).get()
          .then((querySnapshot) => {
            resolve(querySnapshot.data());
          })
          .catch((error: any) => {
            reject(error);
          });
      })
      .catch(err => {
        reject(err);
      })
    });
    return promise;
  }

  consultarCategoria(){
    return new Promise((resolve, reject) => {
      this.consultaCategory.get()
        .then((querySnapshot) => {
          let obj: any = [];
          querySnapshot
            .forEach((doc: any) => {
              obj.push({
                id: doc.id,
                name: doc.data().name
              });
            });
          resolve(obj);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  consultarSubcategoria(idCategory){
    return new Promise((resolve, reject) => {
      this.consultaCategory.doc(idCategory).collection('subcategory').get()
        .then((querySnapshot) => {
          let obj: any = [];

          querySnapshot
            .forEach((doc: any) => {
              obj.push({
                id: doc.id,
                name: doc.data().name
              });
            });
          resolve(obj);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  cadastrarEstablishment(newuser, localizacao, location, category, subcategory, establishmentPhoto, pacote, plano){
    var promise = new Promise((resolve, reject) => {
      const point = this.geo.point(localizacao.latitude,localizacao.longitude);
      this.consultaEstablishment.add({
        name: newuser.name,
        description: newuser.description,
        phone: newuser.phone,
        address: localizacao.address,
        position: point.data,
        location: location,
        pacote: pacote,
        plano: plano,
        web: newuser.web,
        codFilter: newuser.codPesquisa,
        categoryId: category,
        subcategoryId: subcategory,
        logoEstablishment: false,
        data : new Date()
      }).then((snapshot) => {
        this.ref = this.storage.ref('fotoEstabelecimento/'+snapshot.id+'/'+snapshot.id);
        this.task = this.ref.put(establishmentPhoto);
        this.task.snapshotChanges().pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe(url => {
              this.consultaEstablishment.doc(snapshot.id).update({
                imgEstablishment: url
              }).then(() => {
                resolve({ success: true });
              }).catch((err) => {
                reject(err);
              })
            });
          })
        ).subscribe();
      }).catch((err) => {
        reject(err);
      })
    })
    return promise;
  }

  consultarEstablishments(category, subcategory){
    return new Promise((resolve, reject) => {
      this.consultaEstablishment.where('categoryId', '==' , category).where('subcategoryId', '==', subcategory).get()
        .then((querySnapshot) => {
          let obj: any = [];

          querySnapshot
            .forEach((doc: any) => {
              obj.push({
                id: doc.id,
                name: doc.data().name,
                pacote: doc.data().pacote,
                plano: doc.data().plano,
                data: doc.data().data.toDate().getDate() + "/" + (doc.data().data.toDate().getMonth() + 1) + '/' + doc.data().data.toDate().getFullYear()
              });
            });
          resolve(obj);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  consultarEstablishment(idEstablishment){
    return new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).get()
        .then((querySnapshot) => {
          resolve(querySnapshot.data());
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  consultarPromotions(idEstablishment){
    return new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).collection('discount').get()
        .then((querySnapshot) => {
          let obj: any = [];
          querySnapshot
            .forEach((doc: any) => {
              obj.push({
                id: doc.id,
                tituloPromo: doc.data().tituloPromo,
                descriptionPromo: doc.data().descriptionPromo,
                dataCriacao: doc.data().validadeInicioPromo.substring(8, 10) + '/' +  doc.data().validadeInicioPromo.substring(5, 7) + '/' + doc.data().validadeInicioPromo.substring(0, 4),
                dataFimPromo: doc.data().validadeFimPromo.substring(8, 10) + '/' +  doc.data().validadeFimPromo.substring(5, 7) + '/' + doc.data().validadeFimPromo.substring(0, 4)
              });
            });
          resolve(obj);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  consultarCategoriaSelecionada(categoryId){
    return new Promise((resolve, reject) => {
      this.consultaCategory.doc(categoryId).get()
        .then((querySnapshot) => {
          resolve(querySnapshot.data());
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  cadastrarPromotion(newPromo, idEstablishment, porcentagemValor, valorProduto, valorPromocao, porcentagem, brinde, typePromo, hotList, categoryNameDiscount, imgDiscount){
    if(valorProduto != null && valorPromocao != null){
      var promise = new Promise((resolve, reject) => {
        this.consultaEstablishment.doc(idEstablishment).collection('discount').add({
          tituloPromo: newPromo.tituloPromo,
          descriptionPromo: newPromo.descriptionPromo,
          porcentagemPromo: porcentagemValor,
          valorProduto: valorProduto,
          valorPromocao: valorPromocao, 
          validadeInicioPromo: newPromo.validadeInicioPromo,
          validadeFimPromo: newPromo.validadeFimPromo,
          typePromo: typePromo,
          hotList: hotList,
          categoria: categoryNameDiscount,
          votoPositivo: 1,
          votoNegativo: 0,
          data : new Date()
        }).then((snapshot) => {
          this.refPromo = this.storage.ref('fotoEstabelecimento/'+idEstablishment+'/'+snapshot.id);
          this.taskPromo = this.refPromo.put(imgDiscount);
          this.taskPromo.snapshotChanges().pipe(
              finalize(() => {
                this.refPromo.getDownloadURL().subscribe(url => {
                  this.consultaEstablishment.doc(idEstablishment).collection('discount').doc(snapshot.id).update({
                    imgDiscount: url
                  }).then(() => {
                    resolve({ success: true });
                  }).catch((err) => {
                    reject(err);
                  })
                });
              })
            ).subscribe();
        }).catch((err) => {
          reject(err);
        })
      })
      return promise;
    }else if(porcentagem != null){
      var promise = new Promise((resolve, reject) => {
        this.consultaEstablishment.doc(idEstablishment).collection('discount').add({
          tituloPromo: newPromo.tituloPromo,
          descriptionPromo: newPromo.descriptionPromo,
          porcentagemPromo: porcentagem,
          validadeInicioPromo: newPromo.validadeInicioPromo,
          validadeFimPromo: newPromo.validadeFimPromo,
          typePromo: typePromo,
          hotList: hotList,
          categoria: categoryNameDiscount,
          votoPositivo: 1,
          votoNegativo: 0,
          data : new Date()
        }).then((snapshot) => {
          this.refPromo = this.storage.ref('fotoEstabelecimento/'+idEstablishment+'/'+snapshot.id);
          this.taskPromo = this.refPromo.put(imgDiscount);
          this.taskPromo.snapshotChanges().pipe(
              finalize(() => {
                this.refPromo.getDownloadURL().subscribe(url => {
                  this.consultaEstablishment.doc(idEstablishment).collection('discount').doc(snapshot.id).update({
                    imgDiscount: url
                  }).then(() => {
                    resolve({ success: true });
                  }).catch((err) => {
                    reject(err);
                  })
                });
              })
            ).subscribe();
        }).catch((err) => {
          reject(err);
        })
      })
      return promise;
    }else if(brinde != null){
      var promise = new Promise((resolve, reject) => {
        this.consultaEstablishment.doc(idEstablishment).collection('discount').add({
          tituloPromo: newPromo.tituloPromo,
          descriptionPromo: newPromo.descriptionPromo,
          brinde: brinde,
          validadeInicioPromo: newPromo.validadeInicioPromo,
          validadeFimPromo: newPromo.validadeFimPromo,
          typePromo: typePromo,
          hotList: hotList,
          categoria: categoryNameDiscount,
          votoPositivo: 1,
          votoNegativo: 0,
          data : new Date()
        }).then((snapshot) => {
          this.refPromo = this.storage.ref('fotoEstabelecimento/'+idEstablishment+'/'+snapshot.id);
          this.taskPromo = this.refPromo.put(imgDiscount);
          this.taskPromo.snapshotChanges().pipe(
              finalize(() => {
                this.refPromo.getDownloadURL().subscribe(url => {
                  this.consultaEstablishment.doc(idEstablishment).collection('discount').doc(snapshot.id).update({
                    imgDiscount: url
                  }).then(() => {
                    resolve({ success: true });
                  }).catch((err) => {
                    reject(err);
                  })
                });
              })
            ).subscribe();
        }).catch((err) => {
          reject(err);
        })
      })
      return promise;
    }
    
  }

  adicionarLogo(establishmentId, establishmentLogo){
    var promise = new Promise((resolve, reject) => {
      this.ref2 = this.storage.ref('fotoEstabelecimento/'+establishmentId+'/'+establishmentId+'LOGO');
        this.task2 = this.ref2.put(establishmentLogo);
        this.task2.snapshotChanges().pipe(
          finalize(() => {
            this.ref2.getDownloadURL().subscribe(url => {
              this.consultaEstablishment.doc(establishmentId).update({
                logoEstablishment: true,
                imgLogo: url
              }).then(() => {
                resolve({ success: true });
              }).catch((err) => {
                reject(err);
              })
            });
          })
        ).subscribe();
    })
    return promise;
  }

  editPacote(pacote, plano, idEstablishment){
    var promise = new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).update({
        pacote: pacote,
        plano: plano,
      }).then(() => {
        resolve({ success: true });
      }).catch((err) => {
        reject(err);
      })
    })
    return promise;
  }

  /* Duas consultas de alteração na mesma chamada */
  editEstablishment(edituser, idEstablishment){
    var promise = new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).update({
        name: edituser.name,
        description: edituser.description,
        phone: edituser.phone,
        web: edituser.web,
        location: edituser.location,
        categoryId: edituser.categoryId,
        subcategoryId: edituser.subcategoryId,
        codFilter: edituser.subcategoryName
      }).then(() => {
        resolve({ success: true });
      }).catch((err) => {
        reject(err);
      })
    })
    return promise;
  }

  editAddress(localizacaoCompletaEdit, idEstablishment){
    var promise = new Promise((resolve, reject) => {
      const point = this.geo.point(localizacaoCompletaEdit.latitude,localizacaoCompletaEdit.longitude);
      this.consultaEstablishment.doc(idEstablishment).update({
        address: localizacaoCompletaEdit.address,
        position: point.data
      }).then(() => {
        resolve({ success: true });
      }).catch((err) => {
        reject(err);
      })
    })
    return promise;
  }
  /* FIM CONSULTA DUPLA */

  deleteEstablishment(idEstablishment){
    return new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).delete()
        .then(() => {
          resolve();
        }).catch((error: any) => {
          reject(error);
        });
    });
  }

  deletePromotion(idEstablishment, idPromotion){
    return new Promise((resolve, reject) => {
      this.consultaEstablishment.doc(idEstablishment).collection('discount').doc(idPromotion).delete()
        .then(() => {
          resolve();
        }).catch((error: any) => {
          reject(error);
        });
    });
  }

  cadastrarUtility(newUtility, localizacao){
    var promise = new Promise((resolve, reject) => {
      const point = this.geo.point(localizacao.latitude,localizacao.longitude);
      this.consultaUtility.add({
        name: newUtility.name,
        phone: newUtility.phone,
        address: localizacao.address,
        position: point.data
      }).then(() => {
        resolve({ success: true });
      }).catch((err) => {
        reject(err);
      })
    })
    return promise;
  }
}
